import React, { useState } from "react";
import { Input, Form, Typography, Select, Alert, Button, Row, Col } from 'antd';
import QRCodeComponent from "../../qr.code.component";
import { savePixPayment } from '../api';
import { connect } from "react-redux";
import apicalls from "../../../api/apiCalls";
import NumberFormat from "react-number-format";

const PixReceive = (props) => {
    const { Text } = Typography;
    const { Option } = Select;
    const [form] = Form.useForm();
    const [selectedCurrency, setSelectedCurrency] = useState(null);
    const [isCommission, setIsCommission] = useState(false);
    const [commissionObj, setIsCommissionObj] = useState({});
    const [errorMsg, setErrorMsg] = useState(null);
    const [btnLoading, setBtnLoading] = useState(false);
    const [formattedValue, setFormattedValue] = useState('');
    const [isBusinessValue, setIsBusinessValue] = useState('');
    const [amount, setAmount] = useState('');

    const handleCurrency = (currency) => {
        setErrorMsg(null);
        setSelectedCurrency(currency);
        setIsCommission(false);
    };

    const handleInputChange = (e) => {
        setIsCommission(false);
        const { value } = e.target;
        const digits = value.replace(/[^a-zA-Z0-9]/g, '');
        let formattedValue = '';
        if (props?.userConfig?.isBusiness) {
            for (let i = 0; i < digits.length; i++) {
                if (i === 2 || i === 5) {
                    formattedValue += '.';
                } else if (i === 8) {
                    formattedValue += '/';
                } else if (i === 12) {
                    formattedValue += '-';
                }
                formattedValue += digits.charAt(i);
            }
            setIsBusinessValue(formattedValue);
        } else {
            for (let i = 0; i < digits.length; i++) {
                if (i > 0 && i % 3 === 0 && i < 9) {
                    formattedValue += '.';
                }
                if (i === 9) {
                    formattedValue += '-';
                }
                formattedValue += digits.charAt(i);
            }
            setFormattedValue(formattedValue);
        }
        form.validateFields(['documentId']);
    }

   

    const validateDocumentId = (_, value) => {
        const isBusiness = props?.userConfig?.isBusiness;
        let regex;
    
        if (isBusiness) {
            regex = /^([a-zA-Z0-9]{2}\.[a-zA-Z0-9]{3}\.[a-zA-Z0-9]{3}\/[a-zA-Z0-9]{4}\-[a-zA-Z0-9]{2})$/;
        } else {
            regex = /^([a-zA-Z0-9]{3}\.[a-zA-Z0-9]{3}\.[a-zA-Z0-9]{3}\-[a-zA-Z0-9]{2})$/;
        }
    
        if (value && !regex.test(value)) {
            return Promise.reject(new Error('Incorrect Document ID.'));
        }
        return Promise.resolve();
    };
    

    const onCommisson = async (values) => {
        setErrorMsg(null);
        setBtnLoading(true);
        let documentIdValue;
        if (props?.userConfig?.isBusiness) {
            documentIdValue = isBusinessValue;
        } else {
            documentIdValue = formattedValue;
        }
        let obj = {
            "CustomerId": props?.userConfig?.id,
            "Currency": values?.currency || selectedCurrency,
            "AmountinBRL": amount,
            "DocumentId": documentIdValue

        };

        let response = await savePixPayment(obj);

        if (response.ok) {
            setIsCommission(true);
            setBtnLoading(false);
            setIsCommissionObj(response.data);
        } else {
            setBtnLoading(false);
            setErrorMsg(apicalls.isErrorDispaly(response));
        }
    };
    const handleAmountChange = (val) => {
        let amount = val.floatValue;
        setAmount(amount);
        form.setFieldsValue({ amountinbrl: val.floatValue })

    }

    const format = props?.userConfig?.isBusiness === true
        ? "XX.XXX.XXX/XXXX-XX"
        : "NNN.NNN.NNN-NN";

    return (
        <>
            <div className="text-center">
                <div className='text-center selctcoin-style'>
                    <div className='drawer-maintitle'>
                        {props?.userConfig?.isBusiness && "Corporate"}
                        {!props?.userConfig?.isBusiness && "Individual"}

                    </div>
                    <div className='label-style drawer-subtextstyle'>Deposit via PIX</div>
                </div>
            </div>

            {errorMsg !== null && (
                <Alert type="error" description={errorMsg} showIcon />
            )}
            {props?.errorMsg !== null && (
                <Alert type="error" description={props?.errorMsg} showIcon />
            )}

            <Form form={form} onFinish={onCommisson}>
                <Form.Item
                    className="mb-8 px-4 text-white-50 custom-forminput custom-label pt-8 sc-error"
                    name="documentId"
                    label="Document ID"
                    required
                    rules={[
                        {
                            required: true,
                            message: "Is required",
                        },
                        {
                            validator: validateDocumentId
                        }
                    ]}
                ><div>
                        <Input
                            className="cust-input"
                            maxLength={props.userConfig?.isBusiness ? 18 : 14}
                            placeholder={format}
                            onChange={handleInputChange}
                            value={props?.userConfig?.isBusiness ? isBusinessValue : formattedValue}
                            disabled={isCommission}
                        />
                    </div>
                </Form.Item>

                <Form.Item
                    className="mb-8 px-4 text-white-50 custom-forminput custom-label pt-8 sc-error"
                    name="currency"
                    label="Currency"
                    rules={[
                        {
                            required: true,
                            message: "Is required",
                        },
                    ]}
                >
                    <Select
                        className="cust-input"
                        maxLength={100}
                        placeholder="Select Currency"
                        optionFilterProp="children"
                        onChange={handleCurrency}
                        disabled={isCommission}
                    >
                        {props?.pixCurrencies?.map((item, idx) =>
                            <Option key={idx} value={item.name}>{item?.name}</Option>
                        )}
                    </Select>
                </Form.Item>

                <Form.Item
                    className="mb-8 px-4 text-white-50 custom-forminput custom-label pt-8 sc-error"
                    name="amountinbrl"
                    id="amountinbrl"
                    label="Amount in BRL"
                    required
                    rules={[
                        {
                            required: true,
                            message: "Is required",
                        },
                    ]}
                >
                    <NumberFormat
                        className="cust-input mb-0"
                        placeholder="Enter Amount in BRL"
                        decimalScale={2}
                        customInput={Input}
                        thousandSeparator={true}
                        allowNegative={false}
                        maxLength={13}
                        disabled={isCommission}
                        allowLeadingZeros={false}

                        onValueChange={handleAmountChange}
                        value={amount}



                    />
                </Form.Item>

                {!isCommission &&
                    <Row gutter={[16, 16]} className="send-drawerbtn">
                        <Col xs={24} md={24} lg={24} xl={24} xxl={24} className="mobile-viewbtns mobile-btn-pd">
                            <Form.Item>
                                <Button
                                    htmlType={"submit"}
                                    loading={btnLoading}
                                    size="large"
                                    block
                                    className="pop-btn custom-send cust-disabled"
                                >
                                    Continue
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                }
            </Form>

            {isCommission &&
                <Form>
                    <Form.Item
                        className="custom-forminput mb-8"
                        name="amountInUSD"
                        id="currency"
                    >
                        <div>
                            <div className="d-flex">
                                <Text className="label-style">
                                    Amount in {selectedCurrency}
                                </Text>
                            </div>

                            <NumberFormat
                                className="cust-input mb-0"
                                placeholder={`Amount in ${selectedCurrency}`}
                                decimalScale={2}
                                customInput={Input}
                                thousandSeparator={true}
                                disabled={true}
                                value={commissionObj?.amountInCurrency}



                            />
                        </div>
                    </Form.Item>

                    <Form.Item
                        className="custom-forminput"
                        name="AmountInUsdAfterFee"
                        id="currency"
                    >
                        <div>
                            <div className="d-flex">
                                <Text className="label-style">
                                    Amount in {selectedCurrency} (After Fees)
                                </Text>
                            </div>

                            <NumberFormat
                                className="cust-input mb-0"
                                placeholder={`Amount in ${selectedCurrency} (After Fees)`}
                                decimalScale={2}
                                customInput={Input}
                                thousandSeparator={true}
                                disabled={true}
                                value={commissionObj?.amountInAfterFee}
                                maxLength={100}



                            />
                        </div>
                    </Form.Item>

                    <div className="scanner-img pix-qr">
                        <QRCodeComponent
                            value={commissionObj?.qr?.imageUrl}
                            size={200}
                        />
                    </div>
                </Form>
            }
        </>
    );
}

const connectStateToProps = ({ userConfig }) => {
    return {
        userConfig: userConfig.userProfileInfo,
    };
};

export default connect(connectStateToProps)(PixReceive);
